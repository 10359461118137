import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { KeycloakGuard } from '@badgertechnologies/liblogin.js';
import { roleGuardFn } from '@ng-cloud/badger-core/common/guards/functional-guards';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [KeycloakGuard]
  },
  {
    path: 'robots',
    loadChildren: () => import('./robot/robot.module').then(m => m.RobotModule),
    data: { roles: ['installer', 'user', 'verifier', 'depot', 'skybox_level_1'] },
    canMatch: [roleGuardFn]
  },
  {
    path: 'stores',
    loadChildren: () => import('./store/store.module').then(m => m.StoreModule),
    data: { roles: ['user', 'skybox_level_1'] },
    canMatch: [roleGuardFn]
  },
  {
    path: 'skybox',
    loadChildren: () => import('./skybox/skybox.module').then(m => m.SkyboxModule),
    data: { roles: ['phone_support', 'skybox_level_1'] },
    canMatch: [roleGuardFn]
  },
  {
    path: 'organizations',
    loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule),
    data: { roles: ['user'] },
    canMatch: [roleGuardFn]
  },
  {
    path: 'verification', loadChildren: () => import('./verification/verification.module').then(m => m.VerificationModule),
    data: { roles: ['verifier', 'depot'] },
    canMatch: [roleGuardFn]
  },
  {
    path: 'users', loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  { path: 'restricted', redirectTo: '/' },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })]
})
export class AppRoutingModule {
}
