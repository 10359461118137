import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { PlayService } from '../../services/play.service';
import { RobotService } from '../../services/robot.service';
import { RobotModelService } from '../../services/robot-model.service';
import { StoreService } from '../../services/store.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Play } from '../../models/play';
import { Store } from '../../models/store';
import { AppForm } from '../../common/app-form';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
    selector: 'bt-play-form',
    templateUrl: './play-form.component.html',
    styleUrls: ['./play-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PlayFormComponent implements OnInit {
  play: Play;
  store: Store;
  form: AppForm<Play>;
  formGroup: UntypedFormGroup;
  isCreate = true;
  isDuplicate = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PlayFormComponent>,
    private router: Router,
    private playService: PlayService,
    private robotService: RobotService,
    private robotModelService: RobotModelService,
    private storeService: StoreService
  ) {
    this.play = data.play;
    this.storeService.getStore(data.storeId).subscribe(store => {
      this.store = store;
      this.robotService.addRobots(store).subscribe(robots => {
        robots.forEach(robot => {
          this.robotModelService.getRobotModel(robot.robotModelId).subscribe(robotModel => {
            robot.robotModel = robotModel;
          });
        })
      });
    } );
    this.isDuplicate = data.isDuplicate;
    this.isCreate = typeof this.play.id !== 'number';
  }

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      playType: new UntypedFormControl({ value: this.play.playType, disabled: !this.isCreate }, Validators.required),
      name: new UntypedFormControl(this.play.name, Validators.required),
      description: new UntypedFormControl(this.play.description),
      captureOnly: new UntypedFormControl(this.play.captureOnly)
    });

    if (this.isDuplicate) {
      this.formGroup.get('playType').disable();
    }

    this.form = new AppForm(this.formGroup, this.play, new Play(), play => this.onSuccess(play));
  }

  create(): void {
    this.form.submit(play => this.playService.createPlay(play));
  }

  update(): void {
    this.form.submit(play => this.playService.updatePlay(play));
  }

  onSuccess(play) {
    this.dialogRef.close(play);
    if (this.isCreate && !this.isDuplicate) {
      // noinspection JSIgnoredPromiseFromCall
      this.router.navigate([this.router.url, play.id]);
    }
  }
}
