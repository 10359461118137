import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { RobotModel } from '../models/robot-model';
import { HasRobotModel } from '../models/interfaces/has-robot-model';
import * as _ from 'lodash';
import { tap } from 'rxjs/operators';

@Injectable()
export class RobotModelService extends ApiService {

  /**
   * Fetch all RobotModels
   */
  getRobotModels(params?: any): Observable<RobotModel[]> {
    return this.list(RobotModel, 'robot_models', params);
  }

  getRobotModel(id: number): Observable<RobotModel> {
    return this.get(RobotModel, `robot_models/${id}`, id);
  }

  addRobotModels(robots: HasRobotModel[]): Observable<RobotModel[]> {
    const robotsByRobotModelId = _.groupBy(robots, 'robotModelId');
    const ids = _.uniq(robots.map(robot => robot.robotModelId)).sort();
    return this.collectObservable(_.chunk(ids, 200), (idChunk) => this.getRobotModels({ 'id[]': idChunk }).pipe(
        tap((robotModels: RobotModel[]) => {
          robotModels.forEach(robotModel => {
            const robotsByModels = _.get(robotsByRobotModelId, robotModel.id);
            if (robotsByModels) {
              robotsByModels.forEach(robot => {
                robot.robotModel = robotModel;
              });
            }
          });
        })
      ));
  }
}


